<template>
    <div class="workers-page">
        <Navbar />
        <Breadcrumbs></Breadcrumbs>

        <div class="table-title">
            <div class="container-xl">
                <div class="row">
                    <div class="btn-group col-md-4" role="group" aria-label="Basic outlined example">
                        <router-link :to="{ name: 'AbsenceRequests'}" exact-active-class="active" class="btn btn-primary">Zahtevi za odsustvo</router-link>
                        <router-link :to="{ name: 'AllAbsences'}" class="btn btn-primary" exact-active-class="active">Trenutna odsustva</router-link>


                    </div>

                    <!-- <div class="d-flex flex-wrap justify-content-end col-md-8">
                        <div>
                            <div class="form-floating">
                                <input type="date" class="form-control" id="woEndDate" v-model="selected_date" placeholder="Za datum" required @change="changeSelectedDays">
                                <label for="woEndDate">Za datum</label>
                                <div class="invalid-feedback">
                                    Unesite datum
                                </div>
                            </div>
                        </div>
                       
                    </div> -->
                </div>
            </div>
        </div>



        <router-view> </router-view>
        
        <Footer />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue';

export default {
    name: 'Home',
    components: {
        Navbar,
        Footer
    }
}
</script>


<style scoped>

.container {
    min-height: 74vh;
}

.table-title {
    padding-bottom: 15px;
    background: #299be4;
    padding: 16px 30px;
    margin: 0 0 10px;
    border-radius: 3px 3px 0 0;
}

.table-title h2{
    margin: 5px 0 0;
    font-size: 1.8em;
}

.table-title .white-btn {
    height: 100%;    
    background: #fff;
    width: 100%;
}

.table-title .btn.btn-w:hover, .table-title .btn.btn-w:focus {
    color: #566787;
    background: #f2f2f2;
}

.table-title .btn .btn-title {
    margin-top: 20px;
}

.table-wrapper {
    min-width: 1000px;
    background: #fff;
    padding: 20px 25px;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}


.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fcfcfc;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.05);
}

table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
}
table.table thead {
    font-size: 0.8em
}

</style>